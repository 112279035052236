import { Badge, Flex, Grid, GridItem, Icon, Tag, Text } from "@chakra-ui/react";
import Price from "@/components/ProductList/ProductCard/Price";
import { BiCalendar, BiSolidBadgeDollar } from "react-icons/bi";
import { getDollarsDisplayPrice } from "@/utils/price";
import { getShortDateFromEpoch } from "@/utils/date";
import { Product } from "@/hooks/products/utils";
import { FC } from "react";
import { DisplayOptions } from "@/components/ProductList/ProductCard";

type ProductMetaProps = Product & {
  options: DisplayOptions;
  discountApplied?: boolean;
  subTotal?: number;
};

const ProductMeta: FC<ProductMetaProps> = ({
  inStock,
  atp,
  baseVariant,
  options,
  jit,
  customerExpectedDate,
  discountApplied,
  subTotal,
  specs,
}) => {
  const displayVolumeDiscount =
    !jit && baseVariant?.volumeDiscountedPriceLevel && options?.volumeDiscount;
  return (
    <Flex direction="column" gap={0.5}>
      {(!inStock || !atp) && (
        <Flex>
          <Badge variant="solid">
            {options.order ? "Limited stock level" : "Back in stock soon"}
          </Badge>
        </Flex>
      )}
      {((inStock && atp) || options.order) && (
        <GridItem as={Grid} gap={1} area="price">
          <Price
            baseVariant={baseVariant}
            options={options}
            subTotal={subTotal}
          />
          {discountApplied && (
            <Flex as={Text} align="center" gap={0.5} color="blue.500">
              <BiSolidBadgeDollar />
              <Text as="span" fontSize="xs" align="center">
                Discount applied
              </Text>
            </Flex>
          )}
          {displayVolumeDiscount && (
            <Flex as={Text} align="center" gap={0.5} color="blue.500">
              <BiSolidBadgeDollar />
              <Text as="span" fontSize="xs" align="center">
                Buy {baseVariant.volumeDiscountedPriceLevel?.quantity}+ pay{" "}
                {baseVariant.volumeDiscountedPriceLevel?.discountedPrice &&
                  getDollarsDisplayPrice(
                    baseVariant.volumeDiscountedPriceLevel.discountedPrice,
                  )}
              </Text>
            </Flex>
          )}
          {options?.jit && jit && customerExpectedDate && (
            <Text
              as={Flex}
              alignItems="center"
              gap={1}
              color="purple.500"
              fontSize="xs"
            >
              <Icon as={BiCalendar} boxSize={4} /> Get it by{" "}
              {getShortDateFromEpoch(customerExpectedDate)}
            </Text>
          )}
          {options?.specs && specs.length ? (
            <Flex pt={1} gap={1}>
              {specs.map(({ id, name }) => (
                <Tag key={id} size="sm" colorScheme="blue">
                  {name}
                </Tag>
              ))}
            </Flex>
          ) : null}
        </GridItem>
      )}
    </Flex>
  );
};

export default ProductMeta;
